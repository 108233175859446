<template>
  <b-card
    :no-body="true"
    class="chat-application"
  >
    <b-card-body>
      <b-card-title>
        {{ $t('Quick Preview') }}
      </b-card-title>
      <b-card-sub-title>
        {{ $t('This is how the product appear to customers') }}
      </b-card-sub-title>
    </b-card-body>
    <div style="height: inherit">
      <!-- Main Area -->
      <section class="chat-app-window">

        <!-- Start Chat Logo -->
        <div
          v-if="!activeChat.contact"
          class="start-chat-area"
        >
          <div class="mb-1 start-chat-icon">
            <feather-icon
              icon="MessageSquareIcon"
              size="56"
            />
          </div>
          <h4
            class="sidebar-toggle start-chat-text"
          >
            Start Conversation
          </h4>
        </div>

        <!-- Chat Content -->
        <div
          v-else
          class="active-chat"
        >
          <!-- Chat Navbar -->
          <div
            v-if="showHeader"
            class="chat-navbar"
          >
            <header class="chat-header">

              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">
                <b-avatar
                  size="36"
                  class="mr-1 badge-minimal"
                  badge-variant="secondary"
                />
                <h6 class="mb-0 cursor-default">
                  {{ activeChat.contact.fullName }}
                </h6>
              </div>

              <!-- Contact Actions -->
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="VideoIcon"
                  size="17"
                  class="d-sm-block d-none mr-1"
                />
                <feather-icon
                  icon="PhoneCallIcon"
                  size="17"
                  class="d-sm-block d-none mr-1"
                />
                <div class="dropdown">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                    :disabled="true"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="17"
                        class="align-middle text-body"
                      />
                    </template>
                  </b-dropdown>
                </div>
              </div>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            class="user-chats scroll-area"
            style="min-height: 36vh"
          >
            <chat-log
              :chat-data="activeChat"
              profile-user-avatar=""
            />
          </vue-perfect-scrollbar>

          <!-- Message Input -->
          <b-form
            v-if="showMessageInput"
            class="chat-app-form"
          >
            <b-input-group class="input-group-merge form-send-message mr-1">
              <b-form-input
                placeholder="Enter your message"
              />
            </b-input-group>
            <b-avatar
              variant="primary"
              type="button"
              class="btn-icon rounded-circle"
            >
              <feather-icon
                icon="SendIcon"
              />
            </b-avatar>
          </b-form>
        </div>
      </section>
    </div>
  </b-card>
</template>

<script>

import {
  BAvatar,
  BCard, BCardBody, BCardSubTitle, BCardTitle, BDropdown, BForm,
  BFormInput, BInputGroup,
  VBTooltip,
} from 'bootstrap-vue'
import { computed } from 'vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from '@/views/apps/chat/ChatLog.vue'

export default {
  components: {
    BCardBody,
    BCardSubTitle,
    BCardTitle,

    // BSV
    BAvatar,
    BDropdown,
    BForm,
    BInputGroup,
    BFormInput,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLog,

    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
  },
  props: {
    messageText: {
      type: String,
      default: 'Example of your message',
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    showMessageInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
  setup(props) {
    const activeChat = computed(() => ({
      chat: {
        id: 2,
        userId: 11,
        unseenMsgs: 2,
        chat: [
          {
            message: props.messageText.replaceAll('\n', '<br />'),
            time: 'Mon Jan 01 2000 01:02:03 GMT+0000 (GMT)',
            senderId: 1,
          },
        ],
      },
      contact: {
        id: 11,
        fullName: 'Demo Customer',
        avatar: '',
        status: 'online',
        chat: null,
      },
    }))

    return {
      activeChat,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat";
@import "~@core/scss/base/pages/app-chat-list";
</style>
