<template>
  <div>
    <product-details-loader
      v-if="isLoading"
    />
    <section
      v-else
      class="invoice-add-wrapper"
    >
      <b-row class="invoice-add">

        <!-- Col: Left (Main Container) -->
        <b-col
          cols="12"
          xl="8"
          md="8"
        >
          <validation-observer ref="refFormObserver">
            <b-form @submit.prevent>
              <b-row class="match-height">
                <b-col cols="12">
                  <b-card
                    v-for="(formSchema, index) in viewSchema.forms"
                    :key="`form-schema-${index}`"
                    :title="formSchema.title"
                  >
                    <b-card-body>
                      <DynamicForm
                        :schema="formSchema.elements"
                        :value="itemData"
                        @update:value="v => itemData = v"
                      />
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="4"
          class="invoice-actions mt-md-0 mt-2"
        >
          <div class="position-sticky">
            <!-- Action Buttons -->
            <b-card>
              <!-- Button: Publish -->
              <b-button
                v-t="`Save and Exit`"
                variant="primary"
                class="mb-75"
                block
                @click="save(1)"
              />
              <!--          :disabled="false"-->

              <!-- Button: Save -->
              <b-button
                v-t="`Save`"
                variant="outline-primary"
                block
                @click="save(0)"
              />

              <!--          &lt;!&ndash; Button: Preview &ndash;&gt;-->
              <!--          <b-button-->
              <!--            v-t="`Preview`"-->
              <!--            variant="outline-primary"-->
              <!--            class="mb-75"-->
              <!--            block-->
              <!--          />-->

              <!-- Button: Back -->
              <b-button
                v-t="`Exit`"
                variant="outline-secondary"
                class="mb-75"
                block
                @click="exit"
              />
            </b-card>
          </div>

          <!-- Preview -->
          <email-notification-preview
            v-if="channelTypeCode === 'mail'"
            :show-header="false"
            :message-title="itemData.notification_title || $t('modules.notification_templates._messages.example_notification_title')"
            :message-text="messageText || $t('modules.notification_templates._messages.example_notification_body')"
            :show-button="!!itemData.notification_link"
            :button-text="itemData.notification_link_text || $t('modules.notification_templates._messages.email_link_text_example')"
          />
          <whatsapp-notification-preview
            v-else-if="channelTypeCode === 'whatsapp'"
            :show-header="false"
            :message-text="messageText || $t('modules.notification_templates._messages.example_notification_body')"
          />
          <sms-notification-preview
            v-else-if="channelTypeCode === 'sms'"
            :message-text="messageText || $t('modules.notification_templates._messages.example_notification_body')"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  ref, onUnmounted, computed,
} from 'vue'
import { heightTransition } from '@core/mixins/ui/transition'
import router from '@/router'
import store from '@/store'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { getLanguagesList } from '@core/utils/utils'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import ProductDetailsLoader from '@/views/loaders/ProductDetailsLoader.vue'
import WhatsappNotificationPreview from '@/views/models/notification_templates/preview/WhatsappNotificationPreview.vue'
import SmsNotificationPreview from '@/views/models/notification_templates/preview/SmsNotificationPreview.vue'
import EmailNotificationPreview from '@/views/models/notification_templates/preview/EmailNotificationPreview.vue'
import DynamicForm from '@/views/components/forms/DynamicForm.vue'
import notificationTemplateStoreModule from '../notificationTemplateStoreModule'

export default {
  components: {
    DynamicForm,
    EmailNotificationPreview,
    SmsNotificationPreview,
    WhatsappNotificationPreview,
    ProductDetailsLoader,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  computed: {
    //
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs

    if (this.itemData.id) {
      this.fetch()
    }
  },
  created() {
    // window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    // window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getLanguagesList,
  },
  setup() {
    const STORE_MODULE_NAME = 'notification_templates'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, notificationTemplateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const dynamicFieldsCard = ref()
    const itemData = ref({
      id: router.currentRoute.params.id || null,

      name: '',
      notification_type: 'other',
      channel_type: '',
      notification_title: '',
      notification_body: '',
      notification_link: '',
      notification_link_text: '',
    })
    const isLoading = ref(false)
    const viewSchema = ref([])
    const channelOptions = ref([])
    const channelTypeCode = computed(() => itemData.value.channel_type?.id || null)

    const fetchChannels = () => {
      store.dispatch(`${STORE_MODULE_NAME}/fetchNotificationChannels`)
        .then(response => { channelOptions.value = response.data.data })
    }

    const {
      validationErrors,
      fetch,
      save,
      exit,
    } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      data => {
        itemData.value.images_files = data.images // TODO: Check if is effective
        itemData.value = {
          ...itemData.value,
          ...data,
        }
      },
      'notification_templates.edit',
      'notification_templates.list',
    )

    // ---------------------------
    const messageVariables = ref([])
    const fetchOptions = () => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchOptions`)
        .then(response => {
          viewSchema.value = response

          const { forms } = viewSchema.value
          if (forms.length > 0) {
            forms[0].elements.forEach(element => {
              if (element.id === 'channel_type') {
                itemData.value.channel_type = element.options[0]
              } else if (element.id === 'notification_body') {
                messageVariables.value = element.variables
              }
            })
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    fetchOptions()

    const messageText = computed(() => {
      let message = itemData.value.notification_body
      messageVariables.value.forEach(variable => {
        message = message.replace(`{${variable.key}}`, variable.example)
      })
      return message || ''
    })

    return {
      refs,
      isLoading,
      viewSchema,
      dynamicFieldsCard,
      itemData,
      channelTypeCode,
      validationErrors,
      messageText,
      fetch,
      save,
      exit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

//.position-sticky {
//  top: 100px;
//}
</style>
