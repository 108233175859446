<template>
  <b-card
    :title="$t('Quick Preview')"
    :sub-title="$t('This is how the product appear to customers')"
    :no-body="false"
  >
    <div class="message-blue">
      <p
        class="message-content"
        v-html="messageText.replaceAll('\n', '<br />')"
      />
      <div class="message-timestamp-left mt-50">
        {{ nowTime }}
      </div>
    </div>
  </b-card>
</template>

<script>

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  directives: {
  },
  filters: {
  },
  props: {
    messageText: {
      type: String,
      default: 'Example of your message',
    },
  },
  computed: {
  },
  setup() {
    const nowTime = (new Date()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    return {
      nowTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.message-blue {
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #A8DDFD;
  width: 200px;
  //height: 50px;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #97C6E3;
  border-radius: 10px;
}

.message-orange {
  position: relative;
  margin-bottom: 10px;
  margin-left: calc(100% - 240px);
  padding: 10px;
  background-color: #f8e896;
  width: 200px;
  //height: 50px;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #dfd087;
  border-radius: 10px;
}

.message-content {
  padding: 0;
  margin: 0 0 20px 0;
}

.message-timestamp-right {
  position: absolute;
  font-size: .85em;
  font-weight: 300;
  bottom: 5px;
  right: 8px;
}

.message-timestamp-left {
  position: absolute;
  font-size: .85em;
  font-weight: 300;
  bottom: 5px;
  left: 8px;
}

.message-blue:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #A8DDFD;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  left: -15px;
}

.message-blue:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 17px solid #97C6E3;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -1px;
  left: -17px;
}

.message-orange:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 15px solid #f8e896;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  bottom: 0;
  right: -15px;
}

.message-orange:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 17px solid #dfd087;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: -1px;
  right: -17px;
}
</style>
