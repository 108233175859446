<template>
  <b-card
    no-body
    class="email-app-details"
  >
    <b-card-body>
      <b-card-title>
        {{ $t('Quick Preview') }}
      </b-card-title>
      <b-card-sub-title>
        {{ $t('This is how the product appear to customers') }}
      </b-card-sub-title>
    </b-card-body>
    <!-- Email Thread -->
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header
            v-if="showHeader && message.from"
            class="email-detail-head"
          >
            <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
              <b-avatar
                size="48"
                :src="message.from.avatar"
                class="mr-75"
              />
              <div class="mail-items">
                <h5 class="mb-0">
                  {{ message.from.name }}
                </h5>
                <div
                  class="email-info-dropup"
                >
                  <span class="font-small-3 text-muted mr-25">{{ message.from.email }}</span>
                </div>
              </div>
            </div>
            <div class="mail-meta-item d-flex align-items-center">
              <small class="mail-date-time text-muted">{{ formatDate(message.time) }}</small>
              <!-- Mail Action DD -->
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
                class="cursor-default"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="17"
                    class="ml-50 text-body"
                  />
                </template>
              </b-dropdown>
            </div>
          </b-card-header>

          <b-card-body class="mail-message-wrapper pt-0">
            <h5>
              <span class="text-secondary">{{ $t('modules.notification_templates.inputs.message_subject') }}:</span>
              <span class="ml-25">{{ message.subject }}</span>
            </h5>
            <hr>
            <!-- eslint-disable vue/no-v-html -->
            <p><strong>{{ $t('modules.notification_templates._messages.email_greeting_example') }}</strong></p>
            <div
              class="mail-message"
              v-html="message.message"
            />
            <div class="text-center py-50">
              <b-button
                v-if="showButton"
                variant="success"
                disabled
              >
                {{ buttonText || $t('modules.notification_templates._messages.email_link_text_example') }}
              </b-button>
            </div>
            <p>{{ $t('modules.notification_templates._messages.email_salutation_example') }}</p>
            <!-- eslint-enable -->
          </b-card-body>

          <b-card-footer v-if="message.attachments && message.attachments.length">
            <div class="mail-attachments">
              <div class="d-flex align-items-center mb-1">
                <feather-icon
                  icon="PaperclipIcon"
                  size="16"
                />
                <h5 class="font-weight-bolder text-body mb-0 ml-50">
                  {{ message.attachments.length }} Attachment{{ message.attachments.length > 1 ? 's' : '' }}
                </h5>
              </div>

              <div class="d-flex flex-column">
                <b-link
                  v-for="(attachment, index) in message.attachments"
                  :key="index"
                  :href="attachment.url"
                  target="_blank"
                  :class="{'mt-75': index}"
                >
                  <b-img
                    :src="attachment.thumbnail"
                    width="16px"
                    class="mr-50"
                  />
                  <span class="text-muted font-weight-bolder align-text-top">{{ attachment.fileName }}</span>
                  <span class="text-muted font-small-2 ml-25">({{ attachment.size }})</span>
                </b-link>
              </div>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCard,
  BDropdown,
  BCardFooter,
  BAvatar,
  BLink,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BButton,
} from 'bootstrap-vue'
import { computed } from 'vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BButton,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BLink,
    BAvatar,
    BCardFooter,
    BDropdown,
    BCard,
    BCardBody,
    BImg,

    // BSV
    BRow,
    BCol,
  },
  props: {
    messageTitle: {
      type: String,
      default: 'Example of message subject',
    },
    messageText: {
      type: String,
      default: 'Hello customer,\n This is an example of your message',
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    showMessageInput: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const message = computed(() => ({
      from: {
        email: 'no-reply@company.com',
        name: 'Your Company Name',
        avatar: '',
      },
      to: [{ name: 'Customer Name', email: 'customer@email.com' }],
      subject: props.messageTitle,
      cc: [],
      bcc: [],
      message: props.messageText.replaceAll('\n', '<br />'),
      attachments: [],
      isStarred: true,
      labels: [],
      time: (new Date()).toISOString(),
      replies: [],
      folder: 'inbox',
      isRead: true,
    }))

    return {
      message,

      // useEmail
      formatDate,
    }
  },
}
</script>

<style>

</style>
